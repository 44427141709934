import { selectUser, users } from "./development-provider";

// FeatureName type ensures the correct feature name
// identifiers are used everywhere. Also allows autocompletion.
type FeatureName =
  | "Hosted AWS - Role Auth"
  | "Serverless AWS - VPC Configuration"
  | "CloudSQL"
  | "GCPAssets"
  | "Risk Actions"
  | "Assets Exceptions"
  | "Asset Risk Assessment"
  | "Known Exploits"
  | "Developer Tools"
  | "Auto create cases on drift"
  | "Risk Factors Configuration"
  | "Defender Integration"
  | "GitHub Issues"
  | "Registry Deprecation";

export interface FeatureFlag {
  name: FeatureName;
  enabled: boolean;
  description?: string;
}

export const features: FeatureFlag[] = [
  {
    name: "Hosted AWS - Role Auth",
    enabled: false,
    description:
      "Gain access to use the new Hosted AWS integration using Role Authentication.",
  },
  {
    name: "Serverless AWS - VPC Configuration",
    enabled: false,
    description:
      "Enable VPC configuration options within Serverless AWS integration.",
  },
  {
    name: "CloudSQL",
    enabled: false,
    description: "Gain access to use the new Cloud SQL export integration.",
  },
  {
    name: "GCPAssets",
    enabled: false,
    description:
      "Discover all assets when running gcp scans with GCP integration.",
  },
  {
    name: "Risk Actions",
    enabled: false,
    description: "Sidebar access to Risk Actions feature",
  },
  {
    name: "Assets Exceptions",
    enabled: false,
    description: "Enabling setting exceptions for controls -> checks -> assets",
  },
  {
    name: "Asset Risk Assessment",
    enabled: false,
    description: "Enables the Risk Assessment section for a single asset",
  },
  {
    name: "Known Exploits",
    enabled: false,
    description:
      "This toggles on/off the Known Exploits section of a Single Vulnerability page.",
  },
  {
    name: "Developer Tools",
    enabled: false,
    description: "Additional tools for debugging/discovering",
  },
  {
    name: "Auto create cases on drift",
    enabled: false,
    description: "Automatically create cases on drift",
  },
  {
    name: "Risk Factors Configuration",
    enabled: false,
    description:
      "Enables the Risk Factors Configuration section within space settings",
  },
  {
    name: "Defender Integration",
    enabled: false,
    description: "Enable the Microsoft Defender for Cloud integration",
  },
  {
    name: "GitHub Issues",
    enabled: false,
    description: "Enable the GitHub Issues ticketing integration",
  },
  {
    name: "Registry Deprecation",
    enabled: false,
    description: "Enable content management outside of Registry",
  },
];

export const isFeatureEnabled = (name: FeatureName): boolean => {
  const feature = features.find((f) => f.name === name);
  return feature ? feature.enabled : false;
};

const storedFeatures: FeatureFlag[] = JSON.parse(
  localStorage.getItem("features") || "[]",
);
storedFeatures.forEach((sf) => {
  const feature = features.find((f) => f.name === sf.name);
  if (feature) {
    feature.enabled = sf.enabled;
  }
});

// console user manager
export class CliManager {
  users() {
    console.table(users);
  }

  su(token: string) {
    const result = users.find((usr) => usr.token === token);
    selectUser(result);
  }

  features() {
    console.table(features);
  }

  enableFeature(name: FeatureName) {
    this.setFeature(name, true);
  }

  disableFeature(name: FeatureName) {
    this.setFeature(name, false);
  }

  setFeature(name: FeatureName, enabled: boolean) {
    const feature = features.find((f) => f.name === name);
    if (feature) {
      feature.enabled = enabled;
      localStorage.setItem("features", JSON.stringify(features));
      location.reload();
    }
  }
}

// register user manager globally
window.cli = new CliManager();
